
import React, { useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Nav from '../components/nav'
import Sidebar from "../components/sidebar"
import Favicon from '../images/favicon.png'
import Apicontainer from "../components/apicontainer"
import Maincontainer from "../components/maincontainer"
import Heading from "../components/heading"
import Paragraph from "../components/paragraph"
import Badge from "../components/badge"
import Footer from "../components/footer"
import Code from "../components/code"
import CodeResp from "../components/coderesponse"
import Image from "../components/image"
import Alert from "../components/alert"
import Borderholder from "../components/borderholder"
import Readmore from "../components/readmore"
import { Link } from "gatsby"
import Refcopybutton from "../components/refcopybutton"
 
// import Highlight from 'react-highlight'
// import CodeHighlight from '../components/codehighlight'
// import 'highlight.js/styles/vs2015.css';
// import Bigcard from "../components/bigcard"



// egdes.node.item
export const query = graphql`
{
  allCollectionJson {
    edges {
      node {
        item {
          name
          item {
            name
            response {
              _postman_previewlanguage
              body
              name
              originalRequest {
                body {
                  mode
                  options {
                    raw {
                      language
                    }
                  }
                  raw
                }
                header {
                  key
                  name
                  type
                  value
                }
                method
                url {
                  query {
                    description
                    disabled
                    key
                    value
                  }
                  raw
                  path
                  host
                }
              }
            }
            request {
              description
              method
              body {
                raw
                mode
                options {
                  raw {
                    language
                  }
                }
              }
              method
              url {
                host
                path
                raw
                query {
                  description
                  disabled
                  key
                  value
                }
              }
              auth {
                bearer {
                  key
                  type
                  value
                }
               
                type
              }
            }
          }
        }
      }
    }
  }
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___path }) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            frontmatter {
              title
              path
              description
            } 
            html
          }
        }
      }
  }
  `;

const CollectionPage = ({ data, pageContext }) => {
  const [requestData, setRequest] = useState("")
  const [respData, setResponse] = useState("")
  const collection = data.allCollectionJson.edges[0].node
  const allPosts = data.allMarkdownRemark.edges

  function convertToSlug(Text) {
    return Text.toLowerCase()
               .replace(/[^\w ]+/g, '')
               .replace(/ +/g, '-').trim();
  }
  //collection = collection.split('\n').map(JSON.parse);//.edges.node.item
  //{
    // // objectMap(collection)
    // Object.values(collection.item).map(( item, key) => {
    //   return (
    //     <>
    //        <Heading h={1} lp={false} text={item.name} key={ convertToSlug(item.name)} />
    //       {/* {item.item && Object.entries(item.item).map(([nkey, newitem]) => { */}
    //       {item.item && Object.values(item.item).map(( newitem) => { }
    
    // )}
    //      </> )             
    //     }
    let dynamic_menu = [];
    Object.values(collection.item).forEach(function( parent_reference ) {
      // code
      //if( parent_reference.name != "Wallet"){
      let temp_submenu = []
      parent_reference.item && Object.values(parent_reference.item).map(( reference) => { 
        temp_submenu.push({
            url: "/api/#" + convertToSlug( reference.name ),
            label: reference.name,
            image:""
          })
      })

      let temporary_obj = {
        url: "/api/#"  + parent_reference.name+ "-menu",
        label: parent_reference.name,
        // submenu: parent_reference.name
      }
      if( temp_submenu.length > 0 ) {
        temporary_obj.submenu = temp_submenu
      }
      dynamic_menu.push(temporary_obj)
    //}
  });

     // console.log('itemcc ', dynamic_menu)

  const { slug } = pageContext



  return (
    <>

      <Helmet>
        <title>Monnify API Reference Page</title>
        <meta name="description" content="Monnify Developer Documentation " />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />        <meta name="theme-color" content="#03a9f4" />
        <link rel="shortcut icon" href={Favicon} type="image/x-icon" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet"/>

      </Helmet>
      <Borderholder  type="api">
        <Sidebar type="api" apim={dynamic_menu}></Sidebar>
        <Maincontainer>
          <Nav type="api" allAPI={collection}  allPosts={allPosts} dynamic_menu={dynamic_menu} ></Nav> 
           <Apicontainer showshortlink={ false } >
            <div className="mx-3 md:mx-7">
            <div className={ "monnify-bg-color-2 grid grid-cols-12 mt-5 pt-1 pb-0 px-5 md:mr-4"}>
                        <div className="col-span-12  md:mr-4 md:col-span-7 px-4 my-auto">
                            <h1 className={ "text-white  font-semibold text-2xl pb-5 mt-3 sm:text-xs md:text-3xl md:pt-6"}>API Reference</h1>
                            <p className={  "text-white   mb-6 text-xs md:text-base"}> Learn how to integrate Monnify APIs into your applications. </p> 
                        </div>
                        <div className="col-span-12 md:mr-4 md:col-span-5 mb-0 mt-7 md:mt-10 ml-2 flex flex-col ">
                            <Image src="reference.svg" classes="align-bottom b-0 pb-0 mt-auto" />
                        </div>
                    </div>
                    
                    <br />
                    <div className="w-full sm:w-2/3 md:w-3/4 pt-1 px-2">
                    <Heading h={2} lp={false} text="Introduction" key={ convertToSlug("Introduction")} />
                    <Paragraph>
                    The Monnify API provides extensive access to the features available on our dashboard,  enabling you to leverage them for your own application. To get started, you must first create a Monnify account on <a href="https://monnify.com" className="monnify-primary" target={"_blank"}>www.monnify.com</a> and obtain the required API keys for all Monnify integrations. Please note that the Monnify API is secured through Basic Authentication or OAuth (Bearer Tokens).  
                   </Paragraph>
                    <br /> 
  
                    <Heading h={2} lp={false} text="Authentication" key={ convertToSlug("Authentication-main")} />
                    <Paragraph> 
                    <Readmore parttodisplay="Monnify's core security protocol is OAuth 2.0, which authenticates API requests via associated API keys that can be managed directly from the dashboard. Monnify provides an API key, that is used together with a secret key to generate a basic token and a secret key that should be kept confidential and stored exclusively on">  
                      
                      Monnify's core security protocol is OAuth 2.0, which authenticates API requests via associated API keys that can be managed directly from the dashboard. Monnify provides an API key, that is used together with a secret key to generate a basic token and a secret key that should be kept confidential and stored exclusively on the user's servers. 
                      
                      The secret API key provides unrestricted access to Monnify's API and authorizes all API calls. In case of any suspicion of compromise, the secret key can be reset from your Monnify dashboard. 
                      <br /><br />

                      To access Monnify endpoints, an access token is required, which is obtained by submitting a request to the login endpoint using the OAuth2 protocol. When making the request, the authorization header should be formatted as  <div className="bg-gray-200 text-sm px-3 cy-2 py-1 rounded inline text-gray-800" style={{ fontSize:"14px",  paddingLeft:"8px",  paddingRight:"8px"  }}  >
                                  { "Basic base64(ApiKey:SecretKay)"}
                                </div>.

                      
                      <Alert title="Authorization Header">Authorization: TUtfVEVTVF9TQUY3SFI1RjNGOjRTWTZUTkw4Q0szVlBSU0JUSFRSRzJOOFhYRUdDNk5M</Alert> 
                      </Readmore> 
                      </Paragraph> 
                                <br />
                          <br />

                          <Heading h={2} lp={false} text="Webhooks" key={ convertToSlug("webhooks")} />

                        <Paragraph>
                        <Readmore>
                        Webhooks is an API concept that enables applications to automatically communicate with each other without constant polling. Monnify integration sends notifications to a URL on the merchants’ server when specific events such as when payments are being received or when settlements are made to your account, allowing further actions such as sending an email or providing value to the user.
                        
                       Monnify supports webhooks for various events like card transactions, settlement and disbursement completion, and refunds. To implement webhooks on your Monnify integration, it is recommended to follow certain best practices such as validating transaction hash, whitelisting Monnify's IP address, checking for duplicate notifications, and processing complex logic after acknowledging receipt of the notification with a 200 HTTP status code. These practices ensure the integrity and security of the payload, prevent unauthorized requests, avoid redundant processing, and prevent time-out issues.
                        </Readmore>
                        
                        <Heading h="4" text="Monnify Webhook Events and Structure" ></Heading> 
                        
                        
                          <p>
                        As part of the Monnify integration, notifications are automatically sent to your system when certain actions are completed. These notifications trigger corresponding activities on your system, and you can specify URLs for certain activities on your integration. The notifications include an event-type property that indicates what action has taken place, as well as event data containing details of the event.


                        </p>
                        <p>Supported notification event types on Monnify include  <br />
                        <ol className="list-decimal list-inside mb-2">
                            <li>
                            <span className="font-semibold">Successful Collection (for successful payments made on your account)</span>
                            <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventType": "SUCCESSFUL_TRANSACTION",
    "eventData": {
      "product": {
        "reference": "1636106097661",
        "type": "RESERVED_ACCOUNT"
      },
      "transactionReference": "MNFY|04|20211117112842|000170",
      "paymentReference": "MNFY|04|20211117112842|000170",
      "paidOn": "2021-11-17 11:28:42.615",
      "paymentDescription": "Adm",
      "metaData": {},
      "paymentSourceInformation": [
        {
          "bankCode": "",
          "amountPaid": 3000,
          "accountName": "Monnify Limited",
          "sessionId": "e6cV1smlpkwG38Cg6d5F9B2PRnIq5FqA",
          "accountNumber": "0065432190"
        }
      ],
      "destinationAccountInformation": {
        "bankCode": "232",
        "bankName": "Sterling bank",
        "accountNumber": "6000140770"
      },
      "amountPaid": 3000,
      "totalPayable": 3000,
      "cardDetails": {},
      "paymentMethod": "ACCOUNT_TRANSFER",
      "currency": "NGN",
      "settlementAmount": "2990.00",
      "paymentStatus": "PAID",
      "customer": {
        "name": "John Doe",
        "email": "test@tester.com"
      }
    }
  }                        
`}
                                </div>
                              </Code> 
                            </Readmore>
                        </li>
                            <li>
                            <span className="font-semibold">Successful Disbursement (for disbursement transactions with a successful definite status)</span>
                            <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventType": "SUCCESSFUL_DISBURSEMENT",
    "eventData": {
      "amount": 10,
      "transactionReference": "MFDS|20210317032332|002431",
      "fee": 8,
      "transactionDescription": "Approved or completed successfully",
      "destinationAccountNumber": "0068687503",
      "sessionId": "090405210317032336726272971260",
      "createdOn": "17/03/2021 3:23:32 AM",
      "destinationAccountName": "DAMILARE SAMUEL OGUNNAIKE",
      "reference": "ref1615947809303",
      "destinationBankCode": "232",
      "completedOn": "17/03/2021 3:23:38 AM",
      "narration": "This is a quite long narration",
      "currency": "NGN",
      "destinationBankName": "Sterling bank",
      "status": "SUCCESS"
    }
  }                       
`}
                                </div>
                              </Code> 
                            </Readmore>
                        </li><li>
                            <span className="font-semibold">Failed Disbursement (for failed disbursement transactions)</span>
                            <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventData": {
      "amount": 17100,
      "transactionReference": "MFDS10620240708214001015343FR7PL8",
      "fee": 20,
      "transactionDescription": "You do not have sufficient balance to process this request. Please fund your account and try again.",
      "destinationAccountNumber": "8088524531",
      "sessionId": "",
      "createdOn": "08/07/2024 9:40:02 PM",
      "destinationAccountName": "MARVELOUS BENJI",
      "reference": "MF240708214000166415",
      "destinationBankCode": "305",
      "completedOn": "08/07/2024 9:40:07 PM",
      "narration": "AOlifepurse1260077196647628800Transaction",
      "currency": "NGN",
      "destinationBankName": "OPAY",
      "status": "FAILED"
    },
    "eventType": "FAILED_DISBURSEMENT"
  }           
`}
                                </div>
                              </Code> 
                            </Readmore>
                        </li><li>
                            <span className="font-semibold">Reversed Disbursement (for reversed disbursement transactions)</span>
                            <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventType": "REVERSED_DISBURSEMENT",
    "eventData": {
      "transactionReference": "MFDS33920240513211815009133P47MKU",
      "reference": "662d2dcf22132ea227db164e-1715631494637",
      "narration": "Fund Transfer",
      "currency": "NGN",
      "amount": 145708,
      "status": "REVERSED",
      "fee": 8,
      "destinationAccountNumber": "8088523251",
      "destinationAccountName": "Marvelous Benji",
      "destinationBankCode": "305",
      "sessionId": "090405240513211816637369129129",
      "createdOn": "13/05/2023 9:18:16 PM",
      "completedOn": "13/05/2023 9:18:19 PM"
    }
  }            
`}
                                </div>
                              </Code> 
                            </Readmore>
                        </li>
                        <li>
                            <span className="font-semibold">Successful Refund (for successfully processed initiated refunds)</span>
                            <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventType": "SUCCESSFUL_REFUND",
    "eventData": {
      "merchantReason":"defective goods",
      "transactionReference":"MNFY|20190816083102|000021",
      "completedOn":"14/04/2021 4:24:05 PM",
      "refundStatus":"COMPLETED",
      "customerNote":"defects",
      "createdOn":"14/04/2021 4:23:37 PM",
      "refundReference":"ref001",
      "refundAmount":10:00
    }
  }             
`}
                                </div>
                              </Code> 
                            </Readmore> 
                        </li><li>
                          <span className="font-semibold">Failed Refund (for failed initiated refunds)</span>
                          <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventType": "FAILED_REFUND",
    "eventData": {
      "merchantReason":"defective goods",
      "transactionReference":"MNFY|20190816083102|000021",
      "completedOn":"14/04/2021 4:24:05 PM",
      "refundStatus":"FAILED",
      "customerNote":"defects",
      "createdOn":"14/04/2021 4:23:37 PM",
      "refundReference":"ref001",
      "refundAmount":10:00
    }
  }                   
`}
                                </div>
                              </Code> 
                            </Readmore> 
                        </li>
                        <li>
                          <span className="font-semibold">Settlement Completion (for successfully processed settlements to your bank account or wallet)</span>
                          <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventData": {
      "amount": "1199.00",
      "settlementTime": "11/11/2021 02:29:00 PM",
      "settlementReference": "LB8HG1PNZT4ATJGZXQBY",
      "destinationAccountNumber": "6000000249",
      "destinationBankName": "Fidelity Bank",
      "destinationAccountName": "Teamapt Limited234",
      "transactionsCount": 1,
      "transactions": [
        {
          "product": {
            "reference": "2134565wda",
            "type": "2134565wda"
          },
          "transactionReference": "MNFY|26|20211111142601|000001",
          "paymentReference": "MNFY|26|20211111142601|000001",
          "paidOn": "11/11/2021 02:26:02 PM",
          "paymentDescription": "Seg",
          "accountPayments": [
            {
              "bankCode": "000014",
              "amountPaid": "1234.00",
              "accountName": "Okeke Chimezie",
              "accountNumber": "******1070"
            }
          ],
          "amountPaid": "1234.00",
          "totalPayable": "1234.00",
          "accountDetails": {
            "bankCode": "000014",
            "amountPaid": "1234.00",
            "accountName": "Okeke Chimezie",
            "accountNumber": "******1070"
          },
          "cardDetails": {},
          "paymentMethod": "ACCOUNT_TRANSFER",
          "currency": "NGN",
          "paymentStatus": "PAID",
          "customer": {
            "name": "Segun Adeponle",
            "email": "segunadeponle@gmail.com"
          }
        }
      ]
    },
    "eventType": "SETTLEMENT"
  }                   
`}
                                </div>
                              </Code> 
                            </Readmore> 
                        </li>
                        <li>
                          <span className="font-semibold">Completed Oflline Payments</span>
                          <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventData": {
      "product": {
        "reference": "MNF-Tl9Noo0G48000890",
        "type": "OFFLINE_PAYMENT_AGENT"
      },
      "transactionReference": "MNFY|76|20230830171357|000252",
      "invoiceReference": "MNF-Tl9Noo0G48000890",
      "paymentReference": "MNF-Tl9Noo0G48000890",
      "paidOn": "30/08/2023 5:13:57 PM",
      "paymentDescription": "adron",
      "metaData":{
        "phoneNumber":"08088523241",
        "name":"Khalid"
      },
      "destinationAccountInformation": {},
      "paymentSourceInformation": {},
      "amountPaid": 15000,
      "totalPayable": 15000,
      "offlineProductInformation": {
        "amount": 15000,
        "code": "56417",
        "type": "INVOICE"
      },
      "cardDetails": {},
      "paymentMethod": "CASH",
      "currency": "NGN",
      "settlementAmount": 14990,
      "paymentStatus": "PAID",
      "customer": {
        "name": "David Customer",
        "email": "mayluv55@hotmail.co.uk"
      }
    },
    "eventType": "SUCCESSFUL_TRANSACTION"
  }                  
`}
                                </div>
                              </Code> 
                            </Readmore> 
                        </li>
                        <li>
                          <span className="font-semibold">Notification for Rejected Payments</span>
                          <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventData": {
      "metaData": "{"name":"Marvelous","age":"90"}",
      "product": {
        "reference": "MNFY|PAYREF|GENERATED|1687798434397393735",
        "type": "WEB_SDK"
      },
      "amount": 100,
      "paymentSourceInformation": {
        "bankCode": "50515",
        "amountPaid": 40,
        "accountName": "MARVELOUS BENJI",
        "sessionId": "090405230626180003067844645188",
        "accountNumber": "5141901487"
      },
      "transactionReference": "MNFY|85|20230626175354|041855",
      "created_on": "2023-06-26 17:53:55.0",
      "paymentReference": "MNFY|PAYREF|GENERATED|1687798434397393735",
      "paymentRejectionInformation": {
        "bankCode": "035",
        "destinationAccountNumber": "7023576853",
        "bankName": "Wema bank",
        "rejectionReason": "UNDER_PAYMENT",
        "expectedAmount": 100
      },
      "paymentDescription": "lets pay",
      "customer": {
        "name": "Marvelous Benji",
        "email": "benji71@gmail.com"
      }
    },
    "eventType": "REJECTED_PAYMENT"
  }                 
`}
                                </div>
                              </Code> 
                            </Readmore> 
                        </li>

                        <li>
                          <span className="font-semibold">Mandate Status Change</span>
                          <Readmore>
                              <Code  key={"webhok-1234"}>
                                <div language="json" file={"Sample event notification structure"}>
{`
  {
    "eventData": {
      "customerAddress": "Everywhere is an address",
      "endDate": "2024-12-31 08:00:00.0",
      "customerEmailAddress": "ogunnaike.damilare@gmail.com",
      "customerAccountName": "SAMUEL DAMILARE OGUNNAIKE",
      "customerAccountNumber": "2191406799",
      "customerAccountBankCode": "057",
      "customerName": "Damilare Ogunnaike",
      "mandateDescription": "Testing Monnify Mandate",
      "externalMandateReference": "mfy-mandate-102",
      "mandateStatus": "CANCELLED",
      "mandateAmount": 100000,
      "autoRenew": false,
      "mandateCode": "MTDD|01J3GRJH8D58B20VNX1E6GSY1N",
      "contractCode": "626689863141",
      "customerPhoneNumber": "08166189142",
      "startDate": "2024-07-24 08:00:00.0"
    },
    "eventType": "MANDATE_UPDATE"
  }
     
`}
                                </div>
                              </Code> 
                            </Readmore> 
                        </li>
                            </ol>
                            
                        </p>
                        
                        <br />
 <Heading h="4" text="Structure and Sample" ></Heading> 
 <p>A typical event notification structure is of the format:</p>


 <Code  key={"webhok-1234"}>
    <div language="json" file={"Sample event notification structure"}>
      {`{ 
        "eventType": "type_of_event",
        "eventData": {
          "prop1": "value1",
          "prop2": "value2"
        }
      }
      `}
    </div>
</Code> 
 

<br />
 <Heading h="4" text="Transaction Hash Computation" ></Heading> 
 <Readmore>
As a security measure, Monnify computes a hash of the request body whenever it sends a notification and includes it in the request header with the key 'monnify-signature'. To ensure the notification is valid and authorized, you should also calculate the hash and compare it to the one sent by Monnify before accepting or acting on the notification.

 
 To calculate the hash, you can use a SHA-512 encoding of your client secret key and the object of the request body. The formula is: SHA-512(client secret key + object of request body).
 </Readmore>


 <br />
<br />
 <Heading h="4" text="Javascript, PHP, Java Sample Codes:" ></Heading> 
 <Readmore parttodisplay="Expand to see Sample codes on how to compute Transaction Hash in Javascript, PHP, Java etc.">

 <p>Sample Client Key: 91MUDL9N6U3BQRXBQ2PJ9M0PW4J22M1Y</p>
 <p>Sample Request:</p>
 <Code  key={"webhok-9"}>
    <div language="json" file={"Sample Event Data"}>
    
 {`
 {
    "eventData": {
        "product": {
            "reference": "111222333",
            "type": "OFFLINE_PAYMENT_AGENT"
        },
        "transactionReference": "MNFY|76|20211117154810|000001",
        "paymentReference": "0.01462001097368737",
        "paidOn": "17/11/2021 3:48:10 PM",
        "paymentDescription": "Mockaroo Jesse",
        "metaData": {},
        "destinationAccountInformation": {},
        "paymentSourceInformation": {},
        "amountPaid": 78000,
        "totalPayable": 78000,
        "offlineProductInformation": {
            "code": "41470",
            "type": "DYNAMIC"
        },
        "cardDetails": {},
        "paymentMethod": "CASH",
        "currency": "NGN",
        "settlementAmount": 77600,
         "paymentStatus": "PAID",
        "customer": {
            "name": "Mockaroo Jesse",
            "email": "111222333@ZZAMZ4WT4Y3E.monnify"
        }
    },
    "eventType": "SUCCESSFUL_TRANSACTION"
}` }
</div></Code>

 
 <p>Hashed Value:</p>
 <p>f04fb635e04d71648bd3cc7999003da6861483342c856d05ddfa9b2dafacb873b0de1d0f8f67405d0010b4348b721c49fa171d317972618debba6b638aedcd3c</p>

 <Heading h="4" text="Computing Hash in Nodejs" ></Heading> 


 <Code  key={"webhok-12344"}>
    <div language="javascript" file={"Computing Hash in Nodejs"}>
      {` 
const sha512 = require('js-sha512').sha512;

const DEFAULT_MERCHANT_CLIENT_SECRET = '91MUDL9N6U3BQRXBQ2PJ9M0PW4J22M1Y'


const computeHash = (requestBody) => {
    const result = sha512.hmac(DEFAULT_MERCHANT_CLIENT_SECRET, requestBody)
    return result
}

const stringifiedRequestBody = '{"eventData":{"product":{"reference":"111222333","type":"OFFLINE_PAYMENT_AGENT"},"transactionReference":"MNFY|76|20211117154810|000001","paymentReference":"0.01462001097368737","paidOn":"17/11/2021 3:48:10 PM","paymentDescription":"Mockaroo Jesse","metaData":{},"destinationAccountInformation":{},"paymentSourceInformation":{},"amountPaid":78000,"totalPayable":78000,"offlineProductInformation":{"code":"41470","type":"DYNAMIC"},"cardDetails":{},"paymentMethod":"CASH","currency":"NGN","settlementAmount":77600,"paymentStatus":"PAID","customer":{"name":"Mockaroo Jesse","email":"111222333@ZZAMZ4WT4Y3E.monnify"}},"eventType":"SUCCESSFUL_TRANSACTION"}';
const computedHash = computeHash(stringifiedRequestBody);
console.log("Computed hash", computedHash);
 
`}
</div></Code>


 <Heading h="4" text="Computing Hash in PHP" ></Heading> 

 <Code  key={"webhok-12346"}>
    <div language="php" file={"RESPONSE"}>
      {`
<?php

class CustomTransactionHashUtil {

    public static function computeSHA512TransactionHash($stringifiedData, $clientSecret) {
      $computedHash = hash_hmac('sha512', $stringifiedData, $clientSecret);
      return $computedHash;
    }
}

$DEFAULT_MERCHANT_CLIENT_SECRET = '91MUDL9N6U3BQRXBQ2PJ9M0PW4J22M1Y';
$data = '{"eventData":{"product":{"reference":"111222333","type":"OFFLINE_PAYMENT_AGENT"},"transactionReference":"MNFY|76|20211117154810|000001","paymentReference":"0.01462001097368737","paidOn":"17/11/2021 3:48:10 PM","paymentDescription":"Mockaroo Jesse","metaData":{},"destinationAccountInformation":{},"paymentSourceInformation":{},"amountPaid":78000,"totalPayable":78000,"offlineProductInformation":{"code":"41470","type":"DYNAMIC"},"cardDetails":{},"paymentMethod":"CASH","currency":"NGN","settlementAmount":77600,"paymentStatus":"PAID","customer":{"name":"Mockaroo Jesse","email":"111222333@ZZAMZ4WT4Y3E.monnify"}},"eventType":"SUCCESSFUL_TRANSACTION"}';
$computedHash = CustomTransactionHashUtil::computeSHA512TransactionHash($data, $DEFAULT_MERCHANT_CLIENT_SECRET);
echo $computedHash;

?>
 
`}
</div></Code>



 <Heading h="4" text="Computing Hash in Java" ></Heading> 

 <Code  key={"webhok-12347"}>
    <div language="json" file={"Computing Hash in Java"}>
      {`
<?php

public class TransactionHashUtil {

    private static final String HMAC_SHA512 = "HmacSHA512";

    private static String toHexString(byte[] bytes) {
        Formatter formatter = new Formatter();
        for (byte b : bytes) {
            formatter.format("%02x", b);
        }
         return formatter.toString();
    }

    public String calculateHMAC512TransactionHash(String data, String merchantClientSecret)
            throws SignatureException, NoSuchAlgorithmException, InvalidKeyException
    {
        SecretKeySpec secretKeySpec = new SecretKeySpec(merchantClientSecret.getBytes(), HMAC_SHA512);
        Mac mac = Mac.getInstance(HMAC_SHA512);
        mac.init(secretKeySpec);
        return toHexString(mac.doFinal(data.getBytes()));
    }
}
 
`}
</div></Code>
</Readmore>

</Paragraph>
<br />
                                <br />
<Heading h={2} lp={false} text="Going Live" key={ convertToSlug("going live")} />
                                
                                <Paragraph>
                                After successfully uploading necessary documents and meeting compliance requirements, your Monnify account will be activated, and you will be ready to go live. However, before doing so, it is important to check that certain components are properly set up to ensure smooth payment collection, communication between Monnify and your business, and communication between your customers and your business. 

                                <br />
                                <br />
                                <Heading h="4" text="Seamless Payment Collection And Notification" ></Heading> 
                                <Readmore parttodisplay="This guide will outline the necessary steps for setting up seamless payment collection and notification through the use of webhook URLs on your Monnify dashboard">
                                This guide will outline the necessary steps for setting up seamless payment collection and notification through the use of webhook URLs on your Monnify dashboard: <br /><br />
Simply navigate to Developers > Webhook and URLs, populate the transaction completion field with your webhook URL, and save.
</Readmore>
                                <br />
                                <br />
                                <Heading h="4" text="Disabling OTP API Disbursement And IP Whitelisting" ></Heading> 
                                <Readmore parttodisplay="If your application is planning to integrate our disbursement API, it is advisable to disable the sending of OTP to your business email when customers initiate a withdraw">
                                If your application is planning to integrate our disbursement API, it is advisable to disable the sending of OTP to your business email when customers initiate a withdrawal request. <br />
This process can be completed quickly by 
<br />
<ol>
  <li>accessing the "Settings" tab on your dashboard and navigating to "Preferences". 
</li>
<li>Once there, you should toggle the "Require approval for transfers initiated via API" button off and save changes. 
</li>
</ol>
<br />
However, <strong>it is crucial to provide Monnify with your server's IP address for whitelisting purposes</strong>, so that only withdrawal requests from that specific IP address are authorized.


                                <br /><br />
                                If your application will be integrating our disbursement API, you would want to disable the sending of OTP to your business mail when customers are requesting withdrawal. This can be easily done by navigating to Settings > Preferences and toggling the button “Require approval for transfers initiated via API” off, then save changes.
<br />  
However it’s <strong>extremely important you provide your server’s IP to Monnify for whitelisting</strong>, so that only withdrawal requests from that IP address are honoured.
</Readmore>
<b />
<Heading h="4" text="Switching From Monnify Sandbox To Live Mode" ></Heading> 
  <p>
  It's recommended that you change all your Monnify sandbox credentials to their respective live credentials when going live.
  This includes the Monnify Sandbox base_url, API Key and Secret, and Contract Code.
  </p>
  <br />
<Alert title="Monnify Environments">
<strong style={{fontWeight:"600"}}>Sandbox Mode(base_url):</strong> https://sandbox.monnify.com
<br />
<br />
<strong style={{fontWeight:"600"}}>Live Mode(base_url):</strong>  https://api.monnify.com
</Alert> 


</Paragraph>

</div>   
                   
                                <br />      
              
            {
              // objectMap(collection)
              Object.values(collection.item).map(( item, key) => {
                //let key = Math.floor(Math.random() * 19000000)
                
                //{ console.log("********************",key, item) }
                // Pretty straightforward - use key for the key and value for the value.
                // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                /// *kkk*

                return (
                  <>
                  <div key={"reference_page" + convertToSlug(item.name) } className="ml-2 md:htidden">
                     <Heading h={1} lp={false} text={item.name} key={ convertToSlug(item.name) + "-menu" } style={{marginTop: "70px"}} />
                    {/* {item.item && Object.entries(item.item).map(([nkey, newitem]) => { */}
                    {item.item && Object.values(item.item).map(( newitem) => {
                        {/* {item.item && Object.keys(item.item).map(([ newitem]) => { */}
                    {/* {item.item && item.item.forEach(( [newitem]) => { */}
                      { console.log("********************", newitem) }
                      let description = ""
                      if (newitem.request.description){
                        //{ console.log("********************",nkey, newitem.request.description.split("####")) }
                        description = newitem.request.description.split("####")[0]
                      }

                      return (<>
                        <Heading h={7} lp={false}  text={ newitem.name } key={convertToSlug(newitem.name)}  style={{marginTop: "40px"}} refhead={convertToSlug(newitem.name)}/>
                        <Paragraph>
                          <div
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </Paragraph>
                        <Badge text={newitem.request.method} type={newitem.request.method} />
                        {Array.isArray(newitem.request.url.path) && <Badge text={"{{base_url}}/" + newitem.request.url.path.join('/')} type={"BLANK"} />} 
                        <br/>
                        <hr className="m-4" />
                        <Heading h={3} lp={false} text={"Headers"} key={"headers-" + convertToSlug(newitem.name)} />
                        <div  className="m-3 mt-8 " >
                          <span className="font-bold mr-2" style={{
                                  fontWeight:"500"
                                }}
                                >Authorization</span>
                                <div className="bg-gray-200 text-sm px-3 cy-2 py-1 rounded inline text-gray-800" style={{
                                  fontSize:"14px",
                                  paddingLeft:"8px",
                                  paddingRight:"8px"
                                }}
                                >
                                  {newitem.request.auth.type?newitem.request.auth.type:"String"}
                                </div> 
                                <div className={" my-3"} style={{
                                  fontSize:"14px",
                                  paddingBottom:"20px",
                                  color:"rgba(0, 0, 0, 0.7)",
                                  fontWeight:"400",
                                  }}
                                  > 
                                  Authorize this API call by { newitem.request.auth.type == "bearer" && <Link className="link-text" to="/api/#generate-access-token"> generating a token here</Link>   
                                  }  {
                                   
                                      (newitem.request.auth.type == "basic") ?  "  including Authorization header that contains the word 'Basic' followed by a space and a base64-encoded string 'apiKey:clientSecret' i.e. 'Basic base64(ApiKey:SecretKey)'":""
                                   
                                  }

                                  , include the Token in the request header.
                                  
                                </div>
                                </div>
                                
                                         
                        <hr className="m-4" />
                        <Heading h={3} lp={false} text={"Body Params"} key={"body-" + convertToSlug(newitem.name)} />

                        <div className="grid grid-cols-12 md:gap-4 m-2  md:mx-0  pb-0">
                        {/* <div className="col-span-12 md:col-span-6 md:p-4"> */}
                          <div className="col-span-12 md:col-span-6 m-1 md:m-3"> 
                          {/* {JSON.stringify(newitem.request.url.query)} */}
                            {newitem.request.url.query && Object.entries(newitem.request.url.query).map(([qkey, qitem]) => {
                              //  { console.log("ff**********************",qkey, qitem) }
                              return (<div className="mt-5"> 
                                <span className="font-bold mr-2" style={{
                                  fontWeight:"500"
                                }}
                                >{qitem.key} </span>
                                <div className="bg-gray-200 text-sm px-3 cy-2 py-1 rounded inline text-gray-800" style={{
                                  fontSize:"14px",
                                  paddingLeft:"8px",
                                  paddingRight:"8px"
                                }}
                                >
                                  {qitem.value ? qitem.value : "String"}
                                </div><br />
                                <div className="my-3" style={{
                                  fontSize:"14px",
                                  paddingBottom:"20px",
                                  color:"rgba(0, 0, 0, 0.7)",
                                  fontWeight:"400",
                                  }}>
                                  {qitem.description}
                                </div>
                                <hr className="my-3 " />

                              </div>)



                            })
                            }
                          </div>
                          <div className="col-span-12 md:col-span-6 md:p-6" style={{
                            "position":"sticky",
                            "top":"50px",
                            "maxHeight":"900px"
                          }}>
                          {/* {console.log("****************",newitem)} */}
                          {newitem.response && Object.entries([newitem.response[0]]).map(([mkey, mitem]) => {
                          //{console.log("****************",mitem)}
                          let requestBody = ""
                          let response_options = []
                          let use_body = false
                          let response_tab_name = "RESPONSE"
                          newitem && newitem.response && Object.entries(newitem.response).map(([mkey, mitem]) => {
                            response_options.push(mitem)
                          })

                          if (mitem.originalRequest.body != null && (mitem.originalRequest.method==="POST" || mitem.originalRequest.method==="PUT" || mitem.originalRequest.method=== "GET")){
                            //{console.log(mitem.name,"R****************", mitem.originalRequest)}
                            requestBody = ( mitem.originalRequest.method === "GET" )?'curl -H "Accept: application/json" \n' + mitem.originalRequest.url.raw + "     \n" : mitem.originalRequest.body.raw
                            //setRequest(requestData => requestBody)
                            //setResponse(respData => (mitem.body)?mitem.body:"")
                            // console.log("pipipipiii", requestBody)
                              
                            return (<>
                              <Code  key={mkey} >
                                  <div language="json" file={"REQUEST"}>
                                    {
                                      requestBody
                                    }
                                  </div>
                                </Code>
                                <CodeResp  key={mkey} data={response_options}>
                                  <div language="json" file={response_tab_name}>
                                    {
                                    ( !use_body) ?((mitem.body)?mitem.body:( ( mitem.originalRequest.body.mode == "raw")?mitem.originalRequest.body.raw:"...")):use_body
                                    }
                                  </div>
                                </CodeResp>
                                </>)
                          } else {
                            //{console.log(mitem.name,"R****************", mitem.originalRequest)}
                            requestBody = ( mitem.originalRequest.method === "GET" )?'curl -H "Accept: application/json" \n' + mitem.originalRequest.url.raw : 
                            //mitem.originalRequest.body.raw
                            (( requestBody == "" || mitem.originalRequest.method === "POST"  )?`curl --header "Content-Type: application/json" \n --request POST \n --data '{}' \n `+ mitem.originalRequest.url.raw + "    \n" : "...")

                            return (<>
                                <Code  key={mkey} >
                                  <div language="json" file={"REQUEST"}>
                                    {
                                      requestBody 
                                    }
                                  </div>
                                </Code>
                                <CodeResp  key={mkey} data={response_options} >
                                  <div language="json" file={response_tab_name}>
                                    {
                                     (mitem.body)?mitem.body:( ( mitem.originalRequest.body.mode == "raw")?mitem.originalRequest.body.raw:"...")
                                    }
                                  </div>
                                </CodeResp>
                                </>)
                          }
                         
                            })
                            }
                          </div>
                        </div>
                      </>)

                    })}
                    </div>
                  </>
                )




              })
            }

</div>
          </Apicontainer>
          <Footer />

        </Maincontainer>
      </Borderholder>
    </>
  )
}

export default CollectionPage


/*
{newitem.response && Object.entries(newitem.response).map(([mkey, mitem]) => {
                          //{console.log("****************",mkey, mitem)}
                          let requestBody = ""
                          if (mitem.originalRequest.body != null && (mitem.originalRequest.method==="POST" || mitem.originalRequest.method==="PUT")){
                            //{console.log(mitem.name,"R****************", mitem.originalRequest)}
                            requestBody = mitem.originalRequest.body.raw
                            return (<>
                              <Code  key={mkey}>
                                  <div language="json" file={"REQUEST"}>
                                    {
                                      requestBody
                                    }
                                  </div>
                                </Code>
                                <CodeResp  key={mkey}>
                                  <div language="json" file={"RESPONSE"}>
                                    {
                                     (mitem.body)?mitem.body:""
                                    }
                                  </div>
                                </CodeResp>
                                </>)
                          }else{
                            //{console.log(mitem.name,"R****************", mitem.originalRequest)}
                            return (<>
                                <CodeResp  key={mkey}>
                                  <div language="json" file={"RESPONSE"}>
                                    {
                                     (mitem.body)?mitem.body:mitem.originalRequest.body.raw
                                    }
                                  </div>
                                </CodeResp>
                                </>)
                          }
                         
                            })
                            }

*/


