import React, { useState } from "react";
 
const Readmore = ({ children, parttodisplay = false }) => {
const text = children;
const [isReadMore, setIsReadMore] = useState(true);
const toggleReadMore = () => {
	setIsReadMore(!isReadMore);
};


if (! parttodisplay && (typeof text === 'string')  ) {
    parttodisplay = text.slice(0, 150)
}

return (
	<div className="readmoretext">
	{isReadMore ?( (
        typeof text === 'object' &&
        !Array.isArray(text) &&
        text !== null
    )?parttodisplay: parttodisplay ): text}
 	<span onClick={toggleReadMore} className="read-or-hide">
		  {isReadMore ? "...Read more" : "...Show less"}
	</span>
	</div>
);
};


export default Readmore;
